/* Custom CSS for OurProjects component */

/* Heading Style */
.section-heading {
  margin-bottom: 40px;
}

.sec-heading h2 {
  font-size: 36px;
  line-height: 1.2;
  margin-bottom: 15px;
  color: #16846e; /* Text Color */
}

.heading-border {
  display: block;
  height: 2px;
  width: 60px;
  background: #16846e;
  margin: 0 auto;
}

/* Project Detail Style */
.project-detail {
  padding-left: 20px;
}

.project-detail h4 {
  font-size: 24px !important;
  margin-bottom: 10px;
  color: #333; /* Text Color */
}

.project-detail p {
  font-size: 16px;
  line-height: 1.5;
  color: #666; /* Text Color */
}

/* Icon Box Style */
.icon-box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px; /* Adjust Icon Box Width */
  height: 80px; /* Adjust Icon Box Height */
  border-radius: 50%; /* Make Icon Box Round */
  background-color: #dddcdc; /* Icon Box Background Color */
}

.icon-box i {
  font-size: 36px; /* Adjust Icon Size */
  color: #fff; /* Icon Color */
}

/* Hover Effect */
.project-detail a:hover {
  text-decoration: none;
  color: #16846e; /* Hover Text Color */
}

.icon-box:hover {
  background-color: #16846e; /* Hover Background Color */
}

.icon-box i:hover {
  color: #fff; /* Hover Icon Color */
}
